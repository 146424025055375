import React from 'react'
import { Box, Grid, Typography } from '@mui/material';
import CardTypeFirm from './molecules/card';
import { styles } from '../../../../../helpers';
import Slider from 'react-slick';
import ScreenSizeHook from '../../../../../hooks/ScreenSizeHook';

const CardSteps = ({ data, cardMenu, cardMenuActive, setCardMenuActive, infoActive, setInfoActive }) => {

    const screenSizeHook = ScreenSizeHook();

    const anfContainerSyles = {
        ...styles.card_container_maxSecurity,
        ...(cardMenu === 'approvers' && { display: 'none' })
    }

    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <>
            {!['xs', 'sm', 'md'].includes(screenSizeHook) ? (
                <>
                    <Grid item sx={styles.card_container}>

                        <Grid sx={anfContainerSyles}>
                            <Typography sx={styles.card_container_title}>
                                Máxima seguridad legal
                            </Typography>
                            <CardTypeFirm
                                icon={data[0].icon}
                                title={data[0].title}
                                UrlButtom={data[0].url}
                                step={data[0].step}
                                cardMenuActive={cardMenuActive}
                                setCardMenuActive={setCardMenuActive}
                                infoActive={infoActive}
                                setInfoActive={setInfoActive}
                                cardMenu={cardMenu}
                                tramitType={data[0].tramitType}
                            />
                        </Grid>

                    </Grid>

                    <Grid item sx={styles.card_container}>

                        <Grid sx={styles.card_container_midSecurity}>
                            <Typography sx={styles.card_container_title}>
                                Mayor seguridad legal
                            </Typography>
                            <Grid sx={styles.card_container_midSecurity_Box}>
                                <CardTypeFirm
                                    icon={data[1].icon}
                                    title={data[1].title}
                                    UrlButtom={data[1].url}
                                    step={data[1].step}
                                    cardMenuActive={cardMenuActive}
                                    setCardMenuActive={setCardMenuActive}
                                    infoActive={infoActive}
                                    setInfoActive={setInfoActive}
                                    cardMenu={cardMenu}
                                    tramitType={data[1].tramitType}
                                />

                                <CardTypeFirm
                                    icon={data[2].icon}
                                    title={data[2].title}
                                    UrlButtom={data[2].url}
                                    step={data[2].step}
                                    cardMenuActive={cardMenuActive}
                                    setCardMenuActive={setCardMenuActive}
                                    infoActive={infoActive}
                                    setInfoActive={setInfoActive}
                                    cardMenu={cardMenu}
                                    tramitType={data[2].tramitType}
                                />
                            </Grid>
                        </Grid>

                    </Grid>

                    <Grid item sx={styles.card_container}>

                        <Grid sx={styles.card_container_lowSecurity}>

                            <Typography sx={styles.card_container_title}>
                                Buena seguridad legal
                            </Typography>
                            <CardTypeFirm
                                icon={data[3].icon}
                                title={data[3].title}
                                UrlButtom={data[3].url}
                                step={data[3].step}
                                cardMenuActive={cardMenuActive}
                                setCardMenuActive={setCardMenuActive}
                                infoActive={infoActive}
                                setInfoActive={setInfoActive}
                                cardMenu={cardMenu}
                                tramitType={data[3].tramitType}
                            />

                        </Grid>

                    </Grid>
                </>
            ) : (
                <Box className="slider-container">
                    <Slider {...settings} sx={{ margin: '0 auto', display: 'flex', justifyContent: 'center' }}>
                        <Grid item sx={styles.card_container}>

                            <Grid sx={anfContainerSyles}>
                                <Typography sx={styles.card_container_title}>
                                    Máxima seguridad legal
                                </Typography>
                                <CardTypeFirm
                                    icon={data[0].icon}
                                    title={data[0].title}
                                    UrlButtom={data[0].url}
                                    step={data[0].step}
                                    cardMenuActive={cardMenuActive}
                                    setCardMenuActive={setCardMenuActive}
                                    infoActive={infoActive}
                                    setInfoActive={setInfoActive}
                                    cardMenu={cardMenu}
                                    tramitType={data[0].tramitType}
                                />
                            </Grid>

                        </Grid>

                        <Grid item sx={styles.card_container}>

                            <Grid sx={styles.card_container_midSecurity}>
                                <Typography sx={styles.card_container_title}>
                                    Mayor seguridad legal
                                </Typography>
                                <Grid sx={styles.card_container_midSecurity_Box}>
                                    <CardTypeFirm
                                        icon={data[1].icon}
                                        title={data[1].title}
                                        UrlButtom={data[1].url}
                                        step={data[1].step}
                                        cardMenuActive={cardMenuActive}
                                        setCardMenuActive={setCardMenuActive}
                                        infoActive={infoActive}
                                        setInfoActive={setInfoActive}
                                        cardMenu={cardMenu}
                                        tramitType={data[1].tramitType}
                                    />

                                    <CardTypeFirm
                                        icon={data[2].icon}
                                        title={data[2].title}
                                        UrlButtom={data[2].url}
                                        step={data[2].step}
                                        cardMenuActive={cardMenuActive}
                                        setCardMenuActive={setCardMenuActive}
                                        infoActive={infoActive}
                                        setInfoActive={setInfoActive}
                                        cardMenu={cardMenu}
                                        tramitType={data[2].tramitType}
                                    />
                                </Grid>
                            </Grid>

                        </Grid>

                        <Grid item sx={styles.card_container}>

                            <Grid sx={styles.card_container_lowSecurity}>

                                <Typography sx={styles.card_container_title}>
                                    Buena seguridad legal
                                </Typography>
                                <CardTypeFirm
                                    icon={data[3].icon}
                                    title={data[3].title}
                                    UrlButtom={data[3].url}
                                    step={data[3].step}
                                    cardMenuActive={cardMenuActive}
                                    setCardMenuActive={setCardMenuActive}
                                    infoActive={infoActive}
                                    setInfoActive={setInfoActive}
                                    cardMenu={cardMenu}
                                    tramitType={data[3].tramitType}
                                />

                            </Grid>

                        </Grid>
                    </Slider>
                </Box >
            )}

        </>
    )
}

export default CardSteps
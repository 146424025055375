import { createStyles, makeStyles } from "@mui/styles";

export const ModalContractCreatedAndContractStepsStyles = makeStyles((theme) => {
    return createStyles({
        header: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '1rem 0',
            borderTopLeftRadius: '5px',
            borderTopRightRadius: '5px'
        },
        iconImg: {
            width: '50px',
            height: '50px',
            paddingRight: '.5rem'
        },
        closeIcon: {
            position: 'absolute',
            right: '1rem'
        },
        modalContent: {
            padding: '.5rem 2rem',
            textAlign: 'center',
            '& > *': {
                margin: '0 0 .75rem 0'
            }
        },
        textTitle: {
            fontSize: '1.5rem',
            fontFamily: 'PoppinsBold',
            color: theme.palette.primary.main,
            margin: '0',
            padding: '0'
        },
        textSubTitle: {
            fontSize: '.8rem',
            fontFamily: 'PoppinsRegular',
            color: theme.palette.black.main,
            [theme.breakpoints.up('md')]: {
                fontSize: '1rem',
            }
        },
        stepperProgress_Container: {
            margin: '0 auto',
            padding: '0.5rem 0',
            boxSizing: 'border-box',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            [theme.breakpoints.up('md')]: {
                padding: '0.5rem 0',
                maxWidth: '850px'
            }
        },
        stepperProgress_Content: {
            width: '100%'
        },
        stepperProgress_steppersBox: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            position: 'relative'
        },
        stepperProgress_mobileBox: {
            width: '150px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        stepperProgress_mobileBox_Number: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        stepperProgress_MobileArrow: {
            display: 'block',
            [theme.breakpoints.up('md')]: {
                display: 'none'
            }
        },
        stepperProgress_stepText: {
            display: 'none',
            [theme.breakpoints.up('md')]: {
                display: 'inline-block',
                width: '12.5rem',
                textAlign: 'center',
                margin: '0 1rem',
            }
        },
        stepperProgress_stepNumberBox: {
            zIndex: '3',
            backgroundColor: '#e0e0e0',
            color: 'white',
            borderRadius: '10px',
            height: '2.6rem',
            width: '2.6rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: '1px solid #e0e0e0',
            transition: '0.4s ease',
            [theme.breakpoints.up('md')]: {
                height: '3rem',
                width: '3rem',
            }
        },
        stepperProgress_stepNumberBox_active: {
            backgroundColor: '#3498db'
        },
        stepperProgress_progressBar: {
            display: 'none',
            [theme.breakpoints.up('md')]: {
                display: 'block',
                position: 'absolute',
                backgroundColor: '#3498db',
                top: '18%',
                left: '10%',
                borderRadius: '5px',
                height: '.5rem',
                zIndex: '2',
                transition: '0.4s ease'
            }
        },
        stepperProgress_progressBar_2: {
            display: 'none',
            [theme.breakpoints.up('md')]: {
                display: 'block',
                position: 'absolute',
                backgroundColor: '#e0e0e0',
                top: '18%',
                left: '10%',
                borderRadius: '5px',
                height: '0.4rem',
                width: '80%',
                zIndex: '1',
                transition: '0.4s ease'
            }
        },
        stepperResponsiveText: {
            display: 'flex',
            justifyContent: 'center',
            [theme.breakpoints.up('lg')]: {
                display: 'none',
            }
        },
        slider_Container: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            overflow: 'hidden',
            height: '18.5rem',
            [theme.breakpoints.up('md')]: {
                height: '27rem'
            }
        },
        slider_imgBox: {
            display: 'flex',
            justifyContent: 'center',
            [theme.breakpoints.up('md')]: {
                justifyContent: 'center'
            }
        },
        slider_Img: {
            transition: '0.5s ease',
            position: 'absolute',
            width: '12.5rem',
            aspectRatio: 'auto',
            objectFit: 'contain',
            [theme.breakpoints.up('md')]: {
                width: '15.5rem',
            },
            [theme.breakpoints.up('lg')]: {
                width: '17rem',
            }
        },
        slider_Img_Center: {
            scale: '0.8',
            top: '42%',
            left: '23.1%',
            [theme.breakpoints.up('md')]: {
                top: '38%',
                left: '36.25%'
            },
            [theme.breakpoints.up('lg')]: {
                top: '35%',
                left: '36%'
            }
        },
        slider_Img_Left: {
            display: 'none',
            [theme.breakpoints.up('lg')]: {
                display: 'inline-block',
                top: '35%',
                left: '5%',
                opacity: '0.3',
                scale: '0.7'
            }
        },
        slider_Img_Right: {
            display: 'none',
            [theme.breakpoints.up('lg')]: {
                display: 'inline-block',
                top: '35%',
                right: '5%',
                opacity: '0.3',
                scale: '0.7'
            }
        },
        slider_Img_Hidde: {
            display: 'none'
        },
        slider_Img_firstActive: {
            [theme.breakpoints.up('lg')]: {
                left: '36%'
            }
        },
        slider_Img_nextInactive: {
            left: '0',
            [theme.breakpoints.up('lg')]: {
                top: '35%',
                left: '59%'
            }
        },
        footer: {
            backgroundColor: theme.palette.gray.xlight,
            borderTop: `1px solid ${theme.palette.gray.light}`,
            display: 'flex',
            justifyContent: 'center',
            padding: '.3rem 0',
            borderBottomLeftRadius: '5px',
            borderBottomRightRadius: '5px'
        },
        continueButton: {
            textTransform: 'none',
            color: theme.palette.white.main,
            backgroundColor: theme.palette.primary.light,
            width: '10rem',
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
            }
        }
    });
});
import React, { useEffect, useState } from 'react'

import { Box, Button, IconButton, Modal, Typography } from '@mui/material'
import { Close } from '@mui/icons-material';

import { ModalContractCreatedAndContractStepsStyles } from './index.css';
import { ReactComponent as CheckIcon } from '../../assets/icons/check-icon-circular.svg'
import InfoMessage from '../dashboard/contentWelcome/molecules/infoMessage';
import ImgSlider from './molecules/imgSlider';
import img1 from "../../assets/img/img_paymenNotification.png";
import img2 from "../../assets/img/img_receipt.png";
import img3 from "../../assets/img/img_signatoryDocument.png";
import img4 from "../../assets/img/img_userMailSign.png";
import img5 from "../../assets/img/img_signedDocument.png";
import img6 from "../../assets/img/img_documentReview.png";
import img7 from "../../assets/img/img_userIDVeirfyMail.svg";
import img8 from "../../assets/img/img_claveUnica.png";
import img9 from "../../assets/img/img_userID.jpeg";
import img10 from "../../assets/img/img_userPhoto.jpeg";
import { useDispatch, useSelector } from 'react-redux';
import { saveTramitExpressInfo } from '../../store/formExpressTramit';
import { saveTramitANFInfo } from '../../store/formANFTramit';
import { saveContractInfo } from '../../store/contract/contractDetail';

const style = {
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
        xs: 350,
        md: 900
    },
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '5px'
};

const ModalContractCreatedAndContractSteps = ({ tramitCode, tramitType, openModal, setOpenModal }) => {

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const { tramitExpressCreated } = useSelector(state => state.formExpressTramit);
    const { tramitANFCreated } = useSelector(state => state.formANFTramit);
    const { tramitAproversCreated } = useSelector(state => state.contract.contractCreator);
    const dispatch = useDispatch()
    const handleClose = () => {
        setOpen(false)
        setOpenModal && setOpenModal(false);
        if (tramitExpressCreated) {
            dispatch(saveTramitExpressInfo({
                indexe: 'tramitExpressCreated',
                value: null
            }))
        };
        if (tramitANFCreated) {
            dispatch(saveTramitANFInfo({
                indexe: 'tramitANFCreated',
                value: null
            }))
        };
        if (tramitAproversCreated) {
            dispatch(saveContractInfo({
                indexe: 'tramitAproversCreated',
                value: null
            }))
        }
    };

    const styles = ModalContractCreatedAndContractStepsStyles();

    const modalInfo = {
        express: {
            images: [
                { src: img1, alt: "imagen-paso1-tramiteExpress-notificacionDePago" },
                { src: img2, alt: "imagen-paso2-tramiteExpress-reciboDePago" },
                { src: img3, alt: "imagen-paso3-tramiteExpress-notificacionFirmaDelDocumento" },
                { src: img4, alt: "imagen-paso4-tramiteExpress-firmaDocumento" },
                { src: img5, alt: "imagen-paso5-tramiteExpress-documentoFirmado" }
            ],
            text: [
                "Aparecerá un botón para realizar el pago.",
                "Podrá descargar el documento tributario.",
                "El documento estará listo para firmar.",
                "Llegará un correo con el enlace para firmar.",
                "Confirmación de firma y espera de documento."
            ]
        },
        anf: {
            images: [
                { src: img7, alt: "imagen-paso1-tramiteANF-notificacionValidacionDeIdentidad" },
                { src: img8, alt: "imagen-paso2-tramiteANF-paginaClaveUnica" },
                { src: img9, alt: "imagen-paso3-tramiteANF-subirFotosDelCarnet" },
                { src: img10, alt: "imagen-paso4-tramiteANF-subirVideoDeAutorizacion" },
                { src: img1, alt: "imagen-paso5-tramiteANF-NofitifacionDePago" }
            ],
            text: [
                "Primero le llegará un correo electrónico.",
                "Iniciará sesión con clave única.",
                "Subirá su documento de identidad.",
                "Tendrá que grabar un video verificando su identidad.",
                "Llegará un correo con el enlace para firmar."
            ]
        },
        aprovers: {
            images: [
                { src: img6, alt: "imagen-paso1-tramiteAprobadores-revisionDocumento" },
                { src: img1, alt: "imagen-paso2-tramiteAprobadores-notificacionDePago" },
                { src: img2, alt: "imagen-paso3-tramiteAprobadores-reciboDePago" },
                { src: img3, alt: "imagen-paso4-tramiteAprobadores-notificacionFirmaDocumento" },
                { src: img5, alt: "imagen-paso5-tramiteAprobadores-documentoFirmadoSatisfactoriamente" }
            ],
            text: [
                "Descargar y revisar el documento para aprobarlo.",
                "Luego de aprobado, llegará el botón de pago.",
                "Podrá descargar el documento tributario.",
                "El documento estará listo para firmar.",
                "Confirmación de firma y espera de documento."
                /* "Llegará un correo con el enlace para firmar." */
            ]
        }
    }

    useEffect(() => {
        if (openModal) {
            handleOpen(true);
        }
    }, [openModal]);

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box className={styles.header}>
                        {tramitCode && (
                            <>
                                <CheckIcon className={styles.iconImg} />
                                <Typography id="modal-modal-title" className={styles.textTitle}>
                                    Trámite creado con el ID: {tramitCode}
                                </Typography>
                            </>
                        )}

                        <IconButton className={styles.closeIcon}>
                            <Close
                                onClick={handleClose}
                            />
                        </IconButton>
                    </Box>

                    <Box className={styles.modalContent}>
                        <Typography className={styles.textSubTitle}>
                            Pasos de validación y notificación de documento en correo electrónico:
                        </Typography>

                        <InfoMessage
                            icon={'info'}
                            text={'Nota: Para clientes con un convenio mensual, la notificación de pago no será enviada a sus participantes; en su lugar, pasará directamente a firma.'}
                        />

                        <ImgSlider
                            images={modalInfo[tramitType].images}
                            stepsNumbers={modalInfo[tramitType].images.length}
                            text={modalInfo[tramitType].text}
                        />
                    </Box>

                    <Box className={styles.footer}>
                        <Button
                            className={styles.continueButton}
                            onClick={handleClose}
                        >
                            Continuar
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    )
}

export default ModalContractCreatedAndContractSteps
import React, { useEffect, useState } from 'react'
import Slider from 'react-slick';

import { Box, Grid, Typography } from '@mui/material';

import { ProceduresHistoryStyles } from '../../index.css';
import FrequentProceduresCard from '../frequentProceduresCard'
import ScreenSizeHook from '../../../../hooks/ScreenSizeHook';

const FrequentProcedures = ({ data }) => {

    const styles = ProceduresHistoryStyles();
    const [frequentProcedures, setFrequentProcedures] = useState([]);
    const screenSizeHook = ScreenSizeHook();


    useEffect(() => {

        if (data) {
            const frequentProcedureCal = [];

            for (let i = 0; i < data.length; i++) {
                const item = data[i];
                const exist = frequentProcedureCal.some(ele => {
                    return ele.contractType === item.contractType && ele.tramitType === item.tramitType
                });

                if (exist) {
                    const index = frequentProcedureCal.findIndex(ele => {
                        return ele.contractType === item.contractType && ele.tramitType === item.tramitType
                    });

                    frequentProcedureCal[index].qty++
                } else {
                    item.qty = 1
                    frequentProcedureCal.push(item)
                };
            };

            const sortedData = frequentProcedureCal.sort((a, b) => {
                return b.qty - a.qty
            }).filter(ele => {
                return ele.tramitType !== 'FIRMA ANF' && ele.contractType !== 'COMERCIAL'
            });

            const maxIndex = sortedData.length <= 4 ? sortedData.length : 4;

            setFrequentProcedures(sortedData.slice(0, maxIndex));
        };

    }, [data])

    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        speed: 2000,
        autoplaySpeed: 2000,
        cssEase: "linear",
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <Grid container={!['xs', 'sm', 'md'].includes(screenSizeHook)} spacing={1}>
            {frequentProcedures?.length > 0 ? (
                !['xs', 'sm', 'md'].includes(screenSizeHook) ? (
                    <>
                        {frequentProcedures.map(ele => (
                            <FrequentProceduresCard
                                key={ele.contractID}
                                contractType={ele.contractType}
                                iContractTypeFeeID={ele.IContractTypeFeeID}
                                iSignerCount={ele.iSignerCount}
                                processType={ele.processType}
                                tramitType={ele.tramitType}
                            />
                        ))}
                    </>
                ) : (
                    <Box className='slider-container'>
                        <Slider {...settings}>
                            {frequentProcedures.map(ele => (
                                <FrequentProceduresCard
                                    key={ele.contractID}
                                    contractType={ele.contractType}
                                    iContractTypeFeeID={ele.IContractTypeFeeID}
                                    iSignerCount={ele.iSignerCount}
                                    processType={ele.processType}
                                    tramitType={ele.tramitType}
                                />
                            ))}
                        </Slider>
                    </Box>
                )
            ) : (
                <Typography className={styles.secondaryText}>
                    Aun no tienes trámites creados
                </Typography>
            )}
        </Grid>
    )
}

export default FrequentProcedures